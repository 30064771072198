<style lang="sass" scoped>
.title
  font-weight: bold
  font-size: 1.5rem
  color: #555
</style>
<template lang="pug">
div
  .d-flex.justify-content-center.align-items-top(style='min-height: 300px')
    .col-9
      .mb-4
      h3.title.py-3 기본 정보

      div(v-if='menu_config.version')
      pre {{menu_config}}

      button(type='button' @click='add_label') add label
      button(type='button' @click='add_url') add url


</template>

<script>

export default {
  name: 'index',
  props: ['property_id'],
  components: {

  },
  computed: {
    session() {
      return this.$store.state.session
    },
    property() {
      return this.$store.state.property
    },
  },
  mounted() {
    this.load()
  },
  data() {
    return {
      menu_config: {},
    }
  },
  methods: {
    add_label() {
      this.menu_config.blocks.push({
        format: 'label',
        name: '레이블',
      })
    },
    add_url() {
      this.menu_config.blocks.push({
        format: 'url',
        name: '링크',
      })
    },
    async load() {
      try {
        const r = await this.$http.get(`/v1/property/${this.property_id}/menu_config`)
        if (r.data.message != 'ok') throw new Error(r.data.message || '메뉴 가져오기 실패')

        this.menu_config = r.data.menu_config
      } catch (error) {
        this.$modal.show('dialog', {
          title: '알림',
          text: error.message,
        })
      }
    },
    async submit() {
      try {
        const r = await this.$http.put(`/v1/property/${this.property_id}`, this.form)
        if (r.data.message != 'ok') throw new Error(r.data.message || '저장 실패')

        this.load()
        this.$modal.show('dialog', {
          title: '알림',
          text: '저장했습니다.'
        })
        this.$store.dispatch('status flows', this.property_id)
      } catch (error) {
        this.$modal.show('dialog', {
          title: '알림',
          text: error.message,
        })
      }
    }
  },
}
</script>
